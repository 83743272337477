import React from 'react';

import { StaticHeaderNavigation } from './StaticHeaderNavigation';
import { StaticFooterNavigation } from './StaticFooterNavigation';

import CustomerChat from '../CustomerChat';

import TitleMeta from '../layout/TitleMeta';
import SEO from '../layout/SEO';

const Root = ({
  locale = 'zh-HK',
  shortLocale = 'hk',
  localizedMetadata,
  currentLocalizedMetadata,
  siteMetadata,
  children
}: RootProps): React.ReactElement => {
  return (
    <div className="flex flex-col w-full h-screen">
      <TitleMeta
        shortLocale={ shortLocale }
        title={ currentLocalizedMetadata.metadata.title } />
        
      <StaticHeaderNavigation
        locale={ locale }
        shortLocale={ shortLocale }
        localizedMetadata={ localizedMetadata } />
      <SEO 
        title={ currentLocalizedMetadata.metadata.title }
        description={ currentLocalizedMetadata.metadata.description }
        keywords={ currentLocalizedMetadata.metadata.keywords }
        siteUrl={ siteMetadata.siteUrl }
        openGraph={ currentLocalizedMetadata.openGraph } />

      <div className="pt-sm-header-bar lg:pt-header-bar flex-1">
        { children }
      </div>

      <StaticFooterNavigation
        locale={ locale }
        shortLocale={ shortLocale } />
      <CustomerChat/>
    </div>
  );
};

interface RootProps {
  locale: string
  shortLocale: string
  localizedMetadata: any
  currentLocalizedMetadata: any
  siteMetadata: any
  children: any
}

export default React.memo(Root);
