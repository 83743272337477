import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const SEO = ({
  title = '',
  description = '',
  keywords = '',
  siteUrl = '',
  openGraph,
}: SEOProps): React.ReactElement => {
  return (
    <GatsbySeo
      title={ title }
      description={ description }
      metaTags={[
        {
          name: 'keywords',
          content: keywords
        }
      ]}
      openGraph={{
        type: 'website',
        // url: siteUrl + location.pathname,
        url: siteUrl,
        title: openGraph.title,
        description: openGraph.description,
        images: [
          {
            url: openGraph.image.url,
            width: openGraph.image.width,
            height: openGraph.image.height,
            alt: openGraph.image.alt,
          },
        ],
      }}
    />
  )
}

interface SEOProps {
  title: string,
  description: string,
  keywords: string,
  siteUrl: string,
  openGraph: any,
}

export default React.memo(SEO);
