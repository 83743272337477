import React from 'react';
import { Helmet } from 'react-helmet';
import isoLangOptions from "../../utils/isoLangOptions";

const TitleMeta = ({
  shortLocale = 'hk',
  title = ''
}: TitleMetaProps): React.ReactElement => {

  const prefixTitle = (title) => {
    const pefix = {
      'hk': '楷和醫療 Chiron Medical',
      'cn': '楷和医疗 Chiron Medical',
      'en': 'Chiron Medical',
    }

    return `${title} | ${pefix[shortLocale]}`
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: isoLangOptions[shortLocale] || 'zh-hk'
      }}
    >
      <title>{ prefixTitle(title) }</title>
      <script
        defer
        src='https://static.cloudflareinsights.com/beacon.min.js'
        data-cf-beacon='{"token": "288a7bea1cf549759044f11bdb70ef64"}'></script>
    </Helmet>
  )
}

interface TitleMetaProps {
  shortLocale: string
  title: string
}

export default TitleMeta;
