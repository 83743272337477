import React, { useEffect } from "react"
const CustomerChat = React.memo(function CustomerChat(){
  useEffect(()=>{
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml            : true,
        version          : 'v5.0'
      });
    };
    (function(d, s, id) {
      // eslint-disable-next-line prefer-const
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line prefer-const
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      js.async = true;
      js.defer = true;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  })
  return(
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="183660481990052"
        theme_color="#7d9cba"
        locale="zh_HK"
        logged_out_greeting="歡迎來到楷和醫療！關於任何醫療資訊、專科中心開放時間及地址，預約醫生我都可以幫忙。"
        logged_in_greeting="歡迎來到楷和醫療！關於任何醫療資訊、專科中心開放時間及地址，預約醫生我都可以幫忙。"
      />
    </>
  )
});

export default React.memo(CustomerChat);