import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { HeaderNavigation } from "@chironmedical/marketing";

const headerQuery = graphql`
  {
    chiron {
      hk: headerNavigations(locale: "zh-HK") {
        id
        rank
        title

        page {
          id
          pathname
        }

        links {
          id
          rank
          title
          page {
            id
            pathname
          }
        }
      }

      en: headerNavigations(locale: "en-US") {
        id
        rank
        title

        page {
          id
          pathname
        }

        links {
          id
          rank
          title
          page {
            id
            pathname
          }
        }
      }

      cn: headerNavigations(locale: "zh-CN") {
        id
        rank
        title

        page {
          id
          pathname
        }

        links {
          id
          rank
          title
          page {
            id
            pathname
          }
        }
      }
    }
  }
`;

export const StaticHeaderNavigation = ({
  locale,
  shortLocale,
  localizedMetadata,
}: HeaderNavigationProps) => (
  <StaticQuery
    query={headerQuery}
    render={(data) => (
      <HeaderNavigation
        locale={locale}
        localizedMetadata={localizedMetadata}
        headerNavigations={data.chiron[shortLocale]}
      />
    )}
  />
);

export interface HeaderNavigationProps {
  locale: string;
  shortLocale: string;
  localizedMetadata: any;
}
