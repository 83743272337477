import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { FooterNavigation } from "@chironmedical/marketing";

const footerQuery = graphql`
  {
    chiron {
      hk: footerNavigations(locale: "zh-HK") {
        id
        rank
        icon
        title

        links {
          id
          title
          subtitle
          rank
          link
          page {
            id
            pathname
          }
        }
      }

      en: footerNavigations(locale: "en-US") {
        id
        rank
        icon
        title

        links {
          id
          title
          subtitle
          rank
          link
          page {
            id
            pathname
          }
        }
      }

      cn: footerNavigations(locale: "zh-CN") {
        id
        rank
        icon
        title

        links {
          id
          title
          subtitle
          rank
          link
          page {
            id
            pathname
          }
        }
      }
    }
  }
`;

export const StaticFooterNavigation = ({
  locale,
  shortLocale,
}: FooterNavigationProps) => (
  <StaticQuery
    query={footerQuery}
    render={(data) => (
      <FooterNavigation
        locale={locale}
        footerNavigations={data.chiron[shortLocale]}
      />
    )}
  />
);

export interface FooterNavigationProps {
  locale: string;
  shortLocale: string;
}
