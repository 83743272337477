import React from 'react';
import { graphql } from 'gatsby';

import Root from '../components/root/Root';

import { Blocks, PageEditorProvider } from '@chironmedical/marketing';

const pageBuilder = ({ data, pageContext }: pageBuilderProps): React.ReactElement => {
  const page = data.chiron.page;
  const { localizedMetadata, locale, shortLocale } = pageContext;

  const pageTemplate = page.edges.find(edge => edge.node.__typename === 'Chiron_PageTemplate')?.node;
  console.log('pageTemplate', pageTemplate);
  const _blocks = pageTemplate ? JSON.parse(pageTemplate.components) : [];
  const _editedComponents = page.components ? JSON.parse(page.components) : [];

  const blocks = mergeBlocks(_blocks, _editedComponents);

  console.log('blocks', blocks);

  const currentLocalizedMetadata = localizedMetadata.find((metadata) => {
    return metadata.locale === locale;
  });

  return (
    <Root
      locale={ locale }
      shortLocale={ shortLocale }
      localizedMetadata={ localizedMetadata }
      currentLocalizedMetadata={ currentLocalizedMetadata }
      siteMetadata={ data.site.siteMetadata }
    >
      <PageEditorProvider
        _blocks={blocks}
        _editedComponents={_editedComponents}
        _resource={data.chiron}
        _locale={locale}
        typenamePrefix="Chiron_"
        isPage={true}
      >
        <Blocks
          _blocks={blocks}
          builderMode={false}
        />
      </PageEditorProvider>
    </Root>
  );
};

interface pageBuilderProps {
  data: any
  pageContext: any
  location: any
}

export const mergeBlocks = (blocks, needMergeBlocks) => {
  return blocks.map((block) => {
    block.children = mergeBlocks(block.children, needMergeBlocks);

    return needMergeBlocks.find(b => {
      return b.id === block.id;
    }) || block;
  });
}

export const pageBuilderQuery = graphql`
  query pageBuilderQuery($pageId: ID!, $locale: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    chiron {
      page(id: $pageId, locale: $locale) {
        id
        locale
        type

        localizedMetadata {
          locale
          pathname
        }

        components
        pathname
        metadata {
          title
          description
          keywords
        }
        openGraph {
          title
          description
          image {
            alt
            height
            width
            url
          }
        }

        edges {
          node {
            __typename
            ... on Chiron_Article {
              ...editorArticleData
            }
            ... on Chiron_Branch {
              ...editorBranchData
            }
            ... on Chiron_Clinic {
              ...editorClinicData
            }
            ... on Chiron_Person {
              ...editorPersonData
            }
            ... on Chiron_LandingPage {
              ...editorLandingPageData
              edges {
                node {
                  ... on Chiron_Branch {
                    id
                    name
                  }
                }
              }
            }
            ... on Chiron_Event {
              ...editorEventData
            }
            ... on Chiron_SocialResponsibility {
              ...editorSocialResponsibilityData
            }
            ... on Chiron_Promotion {
              ...editorPromotionsData
            }
            ... on Chiron_PageTemplate {
              ...editorPageTemplateData
            }
          }
        }
      }
    }
  }

  fragment editorArticleData on Chiron_Article {
    id
    name
    thumbnail
    postedAt
    excerpt
    content
    tags
    releaseAt

    author {
      id
      titledName
      role
      portrait
    }
    page {
      id
      pathname
    }
  }

  fragment editorBranchData on Chiron_Branch {
    id
    publish
    name
    title
    rank
    services
    issues {
      title
      description
      icon
    }
    abstract
    cardIcon
    buttonIcon
    cover
    page {
      id
      pathname
    }
  }

  fragment editorClinicData on Chiron_Clinic {
    id
    faxs
    name
    cover
    thumbnail
    phones
    emails
    district
    address
    officeHour
    location {
      latitude
      longitude
    }
    page {
      id
      pathname
    }
  }

  fragment editorPersonData on Chiron_Person {
    id
    department
    email
    fax
    mobile
    rank
    medicalCard
    namecard
    portrait
    thumbnail
    cardIcon
    voucher
    publish

    name
    title
    titledName
    organization
    role
    address
    affiliatedHospitals
    featuredServices
    insurancePartners
    qualifications
    page {
      id
      pathname
    }

    branches {
      id
      name
      services
      issues {
        title
        description
        icon
      }
      buttonIcon
    }
    clinics {
      id
      name
      district
      phones
    }
  }

  fragment editorLandingPageData on Chiron_LandingPage {
    id
    name
    abstract
    cover
    page {
      id
      pathname
    }
  }

  fragment editorEventData on Chiron_Event {
    id
    name
    thumbnail
    content
    abstract
    postedAt
    page {
      id
      pathname
    }
  }

  fragment editorSocialResponsibilityData on Chiron_SocialResponsibility {
    id
    name
    thumbnail
    content
    abstract
    postedAt
    page {
      id
      pathname
    }
  }

  fragment editorPromotionsData on Chiron_Promotion {
    id
    name
    cover
    thumbnail
    content
    abstract
    keywords

    postedAt
    releaseAt
    page {
      id
      pathname
    }
  }

  fragment editorPageTemplateData on Chiron_PageTemplate {
    id
    name
    components
  }
`;

export default React.memo(pageBuilder);
